
import { computed, defineComponent } from 'vue';
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';
import { GradeItem, gradeItems } from '@/models';

export default defineComponent({
  components: {
    IconComponent
  },
  props: {
    numSelected: {
      type: Number,
      default: 0
    }
  },
  emits: ['clearSelection', 'gradeSelected'],
  setup(props, { emit }) {
    const disabled = computed(() => {
      return props.numSelected < 1;
    });

    function handleGradeSelected(gradeItem: GradeItem) {
      emit('gradeSelected', gradeItem);
    }

    function handleClearSelected() {
      emit('clearSelection');
    }

    return {
      disabled,
      gradeItems,
      handleClearSelected,
      handleGradeSelected,
      Icon
    };
  }
});
