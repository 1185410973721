
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  PropType,
  watch
} from 'vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import {
  GradedEvent,
  GradedManeuver,
  GradeIconMap,
  gradeItems,
  GradeItem,
  Maneuver,
  Event,
  MifScore
} from '@/models';
import { CanonicalEvent } from '@/models/Event';
import {
  CanonicalManeuver,
  CreateGradedManeuverViewModel
} from '@/models/Maneuver';
import { format } from 'date-fns';
import { useStore } from 'vuex';
import GridGradeSelector from '@/components/event/grid/GridGradeSelectorComponent.vue';
import Icon from '../../icon/Icon';
import eventStaticDatabase from '../../../../cms-generated/eventStaticDatabase.json';

export type CellGradePayload = {
  selectedEvents: GradedEvent[];
  selectedGridCells: EventGridCell[];
  gradeItem: GradeItem;
};

export type EventGridCell = {
  rowId: number;
  columnId: number;
  cellId?: string;
  event: GradedEvent;
  icon?: Icon;
  editable?: boolean;
  maneuver:
    | GradedManeuver
    | CreateGradedManeuverViewModel
    | Record<string, any>;
};

type EventGridRow = EventGridCell[];
type EventGrid = EventGridRow[];

export default defineComponent({
  components: {
    IconComponent,
    GridGradeSelector
  },
  props: {
    unlockedEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    studentEvents: {
      type: Array as PropType<Array<GradedEvent>>,
      required: true
    },
    activeGridCell: {
      type: Object as PropType<EventGridCell>,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maneuvers: {
      type: Array as PropType<Array<Maneuver>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedGridCells = ref([] as EventGridCell[]);
    const selectedGridCell = ref();
    const selectedEvent = ref();
    const selectedNextEvent = ref();
    const selectedEvents = ref([] as GradedEvent[]);
    const lastGridCellSelected = ref();
    const paginationIndex = ref(0);
    const paginationPageLength = ref();
    const gridContainer = ref();

    onMounted(() => {
      store.dispatch('eventModule/fetchManeuvers');
      const containerWidth = gridContainer.value.clientWidth;
      let numEvents = 25;
      if (containerWidth < 250) {
        numEvents = 4;
      } else if (containerWidth < 400) {
        numEvents = 5;
      } else if (containerWidth < 500) {
        numEvents = 6;
      } else if (containerWidth < 600) {
        numEvents = 8;
      } else if (containerWidth < 700) {
        numEvents = 10;
      } else if (containerWidth < 800) {
        numEvents = 12;
      } else if (containerWidth < 900) {
        numEvents = 14;
      } else if (containerWidth < 1000) {
        numEvents = 16;
      } else if (containerWidth < 1250) {
        numEvents = 20;
      }
      paginationPageLength.value = numEvents;
      handlePaginationPageClick(pages.value - 1); // ensure last page by default
    });

    watch(
      () => props.studentEvents.length,
      () => {
        handlePaginationPageClick(pages.value - 1); // ensure last page when data length changes
      }
    );

    const data = computed(() => {
      const events = props.studentEvents
        ?.slice()
        .sort((eventA: GradedEvent, eventB: GradedEvent) => {
          return eventA.date.getTime() - eventB.date.getTime();
        });
      return events ? events : [];
    });

    function handleEventDoubleClick(event: GradedEvent) {
      if (selectedEvent.value && selectedEvent.value.id === event.id) {
        selectedEvent.value = null;
      } else {
        selectedEvent.value = event;
      }
      selectedGridCell.value = null;
      emit('gridEventSelected', selectedEvent.value);
    }

    function handleGridItemDoubleClick(gridCell: EventGridCell) {
      if (!gridCell.maneuver?.editable) return;
      if (
        selectedGridCell.value &&
        selectedGridCell.value.cellId === gridCell.cellId
      ) {
        selectedGridCell.value = null;
      } else {
        selectedGridCell.value = gridCell;
      }
      selectedEvent.value = null;
      emit('gridCellSelected', selectedGridCell.value);
    }

    function handleGridItemClick(evt: MouseEvent, gridCell: EventGridCell) {
      if (!gridCell.maneuver?.editable) return;
      // check to see if its already in the list
      const gridItemIndex = selectedGridCells.value.findIndex(
        (gc: EventGridCell) => gc.cellId === gridCell.cellId
      );
      // remove if already selected
      if (gridItemIndex > -1) {
        selectedGridCells.value.splice(gridItemIndex, 1);
      } else {
        selectedGridCells.value.push(gridCell);
      }
      // Find out if this is a row selection or a column selection
      if (evt.shiftKey) {
        const rowMatch = gridCell.rowId === lastGridCellSelected.value.rowId;
        const columnMatch =
          gridCell.columnId === lastGridCellSelected.value.columnId;
        if (rowMatch) {
          selectGridRow(
            gridCell.rowId,
            gridCell.columnId,
            lastGridCellSelected.value.columnId
          );
        } else if (columnMatch) {
          selectGridColumns(
            gridCell.columnId,
            gridCell.rowId,
            lastGridCellSelected.value.rowId
          );
        } else {
          selectGridBlock(lastGridCellSelected.value, gridCell);
        }
      }
      // set last touched grid item, for shift selections
      lastGridCellSelected.value = gridCell;
      emit('cellClick', gridCell);
    }

    function handleEventNameClick(gradedEvent: GradedEvent) {
      emit('gridEventNameClick', gradedEvent);
    }

    function handleSelectEventCells(
      checked: boolean,
      gradedEvent: GradedEvent
    ) {
      const columnIndex = eventGrid.value.findIndex(
        (eventGridRow: EventGridRow) => {
          return eventGridRow[0].event?.id === gradedEvent.id;
        }
      );
      selectGridColumns(columnIndex, 0, props.maneuvers.length, !checked);
    }

    function handleColumnDeleteClick(gradedEvent: GradedEvent) {
      selectedGridCells.value = [];
      emit('deleteEvent', gradedEvent);
    }

    function selectGridBlock(
      gridCell1: EventGridCell,
      gridCell2: EventGridCell
    ) {
      const top =
        gridCell1.rowId < gridCell2.rowId ? gridCell1.rowId : gridCell2.rowId;
      const right =
        gridCell1.columnId > gridCell2.columnId
          ? gridCell1.columnId
          : gridCell2.columnId;
      const bottom =
        gridCell1.rowId < gridCell2.rowId ? gridCell2.rowId : gridCell1.rowId;
      const left =
        gridCell1.columnId < gridCell2.columnId
          ? gridCell1.columnId
          : gridCell2.columnId;
      for (let i = left; i <= right; i++) {
        selectGridColumns(i, top, bottom);
      }
    }

    function selectGridColumns(
      columnId: number,
      rowId1: number,
      rowId2: number,
      shouldDeSelect = false
    ) {
      const startIndex = rowId1 < rowId2 ? rowId1 : rowId2;
      const endIndex = rowId1 > rowId2 ? rowId1 : rowId2;
      const indexOffset = paginationPageLength.value * paginationIndex.value;
      const paginatedColumnIndex =
        columnId < paginationPageLength.value
          ? columnId
          : columnId - indexOffset;
      const column = eventGrid.value[paginatedColumnIndex];
      for (let i = startIndex; i <= endIndex; i++) {
        const gridCell = column[i];
        const selectedGridItemIndex = selectedGridCells.value.findIndex(
          (gc: EventGridCell) => gc && gridCell && gc.cellId === gridCell.cellId
        );
        // only push if not in the selected
        if (
          gridCell &&
          !shouldDeSelect &&
          selectedGridItemIndex === -1 &&
          gridCell.maneuver.editable
        ) {
          selectedGridCells.value.push(gridCell);
        } else if (shouldDeSelect && gridCell && selectedGridItemIndex > -1) {
          selectedGridCells.value.splice(selectedGridItemIndex, 1);
        }
      }
    }

    function selectGridRow(
      rowIndex: number,
      columnId1: number,
      columnId2: number
    ) {
      let startIndex = columnId1 < columnId2 ? columnId1 : columnId2;
      let endIndex = columnId1 > columnId2 ? columnId1 : columnId2;
      // pagination is fun
      // yes it is
      const indexOffset = paginationPageLength.value * paginationIndex.value;
      startIndex = startIndex - indexOffset;
      endIndex = endIndex - indexOffset;
      for (let i = startIndex; i < endIndex; i++) {
        const column = eventGrid.value[i];
        const gridCell = column[rowIndex];
        const selectedGridItemIndex = selectedGridCells.value.findIndex(
          (gc: EventGridCell) => gc.cellId === gridCell.cellId
        );
        // only push if not in the selected
        if (selectedGridItemIndex === -1 && gridCell.maneuver.editable) {
          selectedGridCells.value.push(gridCell);
        }
      }
    }

    function gridCellIsActive(gridCell: EventGridCell) {
      return selectedGridCells.value.some(
        (gc: EventGridCell) => gc.cellId === gridCell.cellId
      );
    }

    function eventIsActive(event: GradedEvent) {
      return selectedEvents.value.some((e: GradedEvent) => e.id === event.id);
    }

    function handleEventClick(event: GradedEvent) {
      // check to see if its already in the list
      const eventIndex = selectedEvents.value.findIndex(
        (e: GradedEvent) => e.id === event.id
      );
      // remove if already selected
      if (eventIndex > -1) {
        selectedEvents.value.splice(eventIndex, 1);
      } else {
        selectedEvents.value.push(event);
      }
    }

    function formatDate(date: Date) {
      return format(date, 'LLL d yyy').replaceAll(' ', '<br />');
    }

    function handleGradeSelection(gradeItem: GradeItem) {
      const payload: CellGradePayload = {
        selectedEvents: selectedEvents.value,
        selectedGridCells: selectedGridCells.value,
        gradeItem
      };

      emit('gradeEvents', payload);
      clearSelections();
    }

    function clearSelections() {
      selectedGridCells.value = [];
      selectedEvents.value = [];
    }

    function handleCreateEvent() {
      emit('createEvent', selectedNextEvent.value);
      selectedNextEvent.value = null;
    }

    function handlePaginationPrevious() {
      if (paginationIndex.value === 0) return;
      paginationIndex.value = paginationIndex.value - 1;
    }

    function handlePaginationNext() {
      if (paginationIndex.value === pages.value - 1) {
        return;
      }
      paginationIndex.value = paginationIndex.value + 1;
    }

    function handlePaginationPageClick(index: number) {
      paginationIndex.value = index;
    }

    function handleKeyPress(evt: KeyboardEvent) {
      const gradeItem: GradeItem | undefined = gradeItems.find(
        (gradeItem) => gradeItem.key === evt.key
      );
      if (gradeItem) {
        handleGradeSelection(gradeItem);
      }
    }

    /* Return just the paginated values based on pagination index */
    function paginateArray(arr: any[]) {
      let startIndex = paginationIndex.value * paginationPageLength.value;
      let endIndex = startIndex + paginationPageLength.value;
      return arr.slice(startIndex, endIndex);
    }

    const pages = computed(() => {
      return Math.ceil(props.studentEvents.length / paginationPageLength.value);
    });

    const isLastPage = computed(() => {
      return paginationIndex.value === pages.value - 1;
    });

    const isFirstPage = computed(() => {
      return paginationIndex.value === 0;
    });

    // Reactive grid data - source of truth for the grid
    const headerData = computed(() => {
      return paginateArray(data.value);
    });

    // Reactive grid data - source of truth for the grid
    const eventGrid = computed(() => {
      const events = data.value;
      if (!events) return [];
      const gridMatrix: EventGrid = events.map(
        (event: GradedEvent, eventIndex: number) => {
          const jaggedRow: EventGridRow = props.maneuvers.map(
            (canonicalManeuver, index) => {
              let foundManeuver:
                | GradedManeuver
                | undefined = event.maneuvers.find(
                (m) => m.name === canonicalManeuver.formattedManeuver
              );

              // const icon = getIconForGradedManeuver(foundManeuver);

              if (foundManeuver === undefined) {
                const eventStaticDB =
                  eventStaticDatabase[
                    'aetc-syllabus-p-v4u-rn-november-16-2021-10-41-pm'
                  ];
                eventStaticDB.map((m) => {
                  return m;
                });
                const foundCanonicalEvent:
                  | CanonicalEvent
                  | undefined = eventStaticDB.find(
                  (canonicalEvent) => canonicalEvent.name === event.name
                );

                if (foundCanonicalEvent === undefined) {
                  throw Error(
                    'Event is missing canonical representation. Check eventStaticDatabase.'
                  );
                }

                const foundCanonicalManeuver:
                  | CanonicalManeuver
                  | undefined = foundCanonicalEvent.maneuvers.find(
                  (m) => m.name === canonicalManeuver.formattedManeuver
                );

                if (foundCanonicalManeuver === undefined) {
                  throw Error(
                    'Event canonical representation is missing a definition for the requested maneuver. Check eventStaticDatabase.'
                  );
                }
                const newManeuver = CreateGradedManeuverViewModel.fromJson({
                  name: foundCanonicalManeuver.name,
                  mif: {
                    value: foundCanonicalManeuver.mif.value as MifScore,
                    formattedManeuver:
                      foundCanonicalManeuver.mif.formattedManeuver,
                    unFormattedManeuver:
                      foundCanonicalManeuver.mif.unFormattedManeuver
                  },
                  eventId: event.id
                });
                return {
                  cellId: `${index}-${eventIndex}`,
                  maneuver: newManeuver.toJson(),
                  event,
                  rowId: index,
                  columnId: eventIndex
                };
              } else if (foundManeuver.loading || event.loading) {
                return {
                  cellId: `${index}-${eventIndex}`,
                  maneuver: {
                    grade: foundManeuver.grade,
                    loading: true,
                    name: 'loading_shim',
                    icon: GradeIconMap.get(foundManeuver.grade),
                    editable: true
                  },
                  event,
                  rowId: index,
                  columnId: eventIndex
                };
              } else {
                return {
                  cellId: `${index}-${eventIndex}`,
                  maneuver: foundManeuver.toJson(),
                  event,
                  rowId: index,
                  columnId: eventIndex
                };
              }
            }
          );
          return jaggedRow;
        }
      );
      const paginatedSlice = paginateArray(gridMatrix);
      return paginatedSlice;
    });

    return {
      clearSelections,
      headerData,
      eventGrid,
      eventIsActive,
      GradeIconMap,
      gridCellIsActive,
      gridContainer,
      formatDate,
      handleColumnDeleteClick,
      handleEventNameClick,
      handleSelectEventCells,
      handleCreateEvent,
      handleKeyPress,
      handleGridItemClick,
      handleEventDoubleClick,
      handleGridItemDoubleClick,
      handleGradeSelection,
      handleEventClick,
      handlePaginationPageClick,
      handlePaginationPrevious,
      handlePaginationNext,
      paginationIndex,
      paginationPageLength,
      pages,
      selectedNextEvent,
      selectedEvent,
      selectedEvents,
      selectedGridCell,
      selectedGridCells,
      isFirstPage,
      isLastPage
    };
  }
});
